export const styles = theme => ({
  '@global': {
    body: {
      ...theme.typography.body1
    },
    ...theme.squareplan.globalCustomStyles
  },
  marketingPageCtr: {},
  pageContainer: {
    minHeight: `calc(var(--vh, 1vh) * 100 - ${theme.squareplan.header.height.height}px - ${theme.squareplan.footer.height.minHeight}px)`
  },
  pageContainerWhite: {
    minHeight: `calc(var(--vh, 1vh) * 100 - ${theme.squareplan.header.height.height}px - ${theme.squareplan.footer.height.minHeight}px)`,
    background: '#fff'
  },
  ctaDialogCtr: {
    backgroundColor: theme.palette.background.yellow,
    padding: theme.spacing(7),
    borderRadius: theme.spacing(2)
  },
  ctaDialogActionsCtr: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center !important'
    }
  },
  ctaDialogContentText: {
    color: theme.palette.text.primary,
    '& h5': {
      lineHeight: '2rem'
    }
  },
  ctaBtn: {
    padding: `${theme.spacing(1.3)}px ${theme.spacing(2.5)}px ${theme.spacing(
      1.5
    )}px ${theme.spacing(2.5)}px`,
    borderRadius: theme.spacing(4),
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center'
    }
  }
});
