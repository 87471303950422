// @flow
import React, { Component} from 'react';
import Grid from '@material-ui/core/Grid';
import {Helmet} from 'react-helmet';
import Logo from '../images/askneo-logo.png';
import {styles} from '../components/index.Style';

type Props = {
  classes: Object
}

const HomePage = (props: Props) => {

  const { classes } = props;
  return (
    <Grid
      container
      justify="space-between"
      alignItems="stretch"
      style={{flexGrow: 1, height: '100vh', padding: 30}}
    >
      <Grid item xs={12} style={{flexGrow: 1, marginBottom: -145, height: '100%'}}>
        <Grid container style={{flexGrow: 1}}>
          <Grid item xs={12} >
            <Helmet>
              <title>Page not found</title>
            </Helmet>
              <a href="/"><img src={Logo} height={30} /></a>
          </Grid>
          <Grid item xs={12} style={{marginTop: 16}}>
            Oops! This page doesn't exist. <br /><br />
            Click <a href="/">here</a> to go back home.
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid item xs={12} style={{height: 145}}>
        <Footer />
      </Grid> */}
    </Grid>
  );
};

export default HomePage;
